import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.0.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

const Presentation = lazy(() => import("views/Presentation.jsx"));
const DetailPage = lazy(() => import("views/DetailPage/DetailPage.jsx"));
const Contact = lazy(() => import("views/ContactUs/ContactUs.jsx"));
const Client = lazy(() => import("views/ClientFlow/Client.jsx"));
const Branding = lazy(() => import("views/Branding.jsx"));
const Consulting = lazy(() => import("views/Consulting.jsx"));
const Creative = lazy(() => import("views/Creative.jsx"));
const Photography = lazy(() => import("views/Photography.jsx"));

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          path="/branding/1800-flowers.com"
          exact
          render={(props) => <DetailPage {...props} />}
        />
        <Route
          path="/photography/1800-flowers.com"
          exact
          render={(props) => <DetailPage {...props} />}
        />

        <Route path="/branding" render={(props) => <Branding {...props} />} />
        <Route
          path="/consulting"
          render={(props) => <Consulting {...props} />}
        />
        <Route path="/creative" render={(props) => <Creative {...props} />} />
        <Route
          path="/photography"
          render={(props) => <Photography {...props} />}
        />

        <Route path="/contact" render={(props) => <Contact {...props} />} />
        <Route path="/work-list" render={(props) => <Client {...props} />} />
        <Route path="/" render={(props) => <Presentation {...props} />} />
        <Redirect from="/" to="/presentation" />
      </Switch>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);
